import React from "react"

import Hero from "../components/hero"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Hero>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Hero>
  </Layout>
  
)

export default NotFoundPage
